import { Flex, Grid, Space, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RaffleItem } from '../raffle-item';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useRaffleStore } from '../../RaffleStore';
import { RaffleTime } from '@web/common';
import { useWindowSize } from '../../../../../../hooks';
import { InteractiveText } from '../../../../../../component/InteractiveText';
import { Routes } from '../../../../../../route/Routes';

export const ActiveRaffles = observer(
  ({
    limit = 3,
    hideHeader = false,
    grow = false,
  }: {
    limit?: number;
    hideHeader?: boolean;
    grow?: boolean;
  }) => {
    const isSmallScreen = useWindowSize(600);
    const { t } = useTranslation('raffle');
    const store = useRaffleStore();
    const hasMoreItems = store.activeRaffles?.length > 3;

    useEffect(() => {
      store.loadActiveRaffles();
    }, [store]);

    return (
      <>
        {!hideHeader && (
          <>
            <Space h="20px" />
            <Flex justify="space-between">
              <Text size="21px" lh="24px" fw="600">
                {t('ActiveRaffles')}
              </Text>
              {!isSmallScreen && hasMoreItems && (
                <InteractiveText
                  text={t('ShowAll')}
                  navigateTo={`${Routes.Study}/${Routes.Raffles}/${Routes.PastRaffles}`}
                />
              )}
            </Flex>
          </>
        )}

        <Grid grow={grow}>
          {store.activeRaffles?.slice(0, limit).map((raffle) => (
            <Grid.Col span={{ base: 12, md: 6 }} key={raffle.sys.id}>
              <RaffleItem
                key={raffle.sys.id}
                data={raffle}
                time={RaffleTime.Active}
              />
            </Grid.Col>
          ))}
        </Grid>

        {!hideHeader && isSmallScreen && hasMoreItems && (
          <InteractiveText
            text={t('ShowAll')}
            navigateTo={`${Routes.Study}/${Routes.Raffles}/${Routes.PastRaffles}`}
          />
        )}
      </>
    );
  },
);
