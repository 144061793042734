import { Space, Flex, Text, Grid } from '@mantine/core';
import { InteractiveText } from '../../../../../../component/InteractiveText';
import { Routes } from '../../../../../../route/Routes';
import { useWindowSize } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { RaffleItem } from '../raffle-item';
import { observer } from 'mobx-react';
import { useRaffleStore } from '../../RaffleStore';
import { useEffect } from 'react';
import { RaffleTime } from '@web/common';

export const PastRaffles = observer(
  ({ hideHeader = false }: { hideHeader?: boolean }) => {
    const isSmallScreen = useWindowSize(600);
    const { t } = useTranslation('raffle');
    const store = useRaffleStore();
    const hasMoreItems = store.pastRaffles?.length > 3;

    useEffect(() => {
      store.loadPastRaffleData();
    }, [store]);

    return (
      <>
        {!hideHeader && (
          <>
            <Space h="20px" />
            <Flex justify="space-between">
              <Text size="21px" lh="24px" fw="600">
                {t('PastRaffles')}
              </Text>
              {!isSmallScreen && hasMoreItems && (
                <InteractiveText
                  text={t('ShowAll')}
                  navigateTo={`${Routes.Study}/${Routes.Raffles}/${Routes.PastRaffles}`}
                />
              )}
            </Flex>
          </>
        )}
        <Grid>
          {store.pastRaffles?.slice(0, 3).map((raffle) => (
            <Grid.Col span={{ base: 12, md: 6 }} key={raffle.sys.id}>
              <RaffleItem data={raffle} time={RaffleTime.Past} />
            </Grid.Col>
          ))}
        </Grid>

        {!hideHeader && isSmallScreen && hasMoreItems && (
          <InteractiveText
            text={t('ShowAll')}
            navigateTo={`${Routes.Study}/${Routes.Raffles}/${Routes.PastRaffles}`}
          />
        )}
      </>
    );
  },
);
