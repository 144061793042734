import {
  ErrorCode,
  type InventoryQueryReqDto,
  type RafflesResDto,
  type RaffleItemResDto,
  type RaffleWinnersResDto,
} from '@web/common';
import { setupMobX } from '../../../../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  activeRaffleData,
  betOnRaffle,
  futureRaffleData,
  pastRaffleData,
  raffleByIdData,
  winnerData,
} from '../../../../request/authenticated-requests/raffle';
import { showErrorNotification } from '../../../../component/notification';
import { handleRequestError } from '../../../../request';

export class RaffleStore {
  @observable public activeRaffles: RafflesResDto = [];
  @observable public upcomingRaffles: RafflesResDto = [];
  @observable public pastRaffles: RafflesResDto = [];
  @observable public specificRaffle: RaffleItemResDto | null = null;
  @observable public winnerData: RaffleWinnersResDto = [];

  @observable public error: string | null = null;
  @observable public queryFilter: InventoryQueryReqDto = {};

  @observable public isLoading = false;
  @observable public isLoadingSpecificRaffle = false;

  constructor() {
    makeObservable(this);
  }

  @action public loadActiveRaffles = () => {
    this.isLoading = true;

    activeRaffleData()
      .then((data) => runInAction(() => (this.activeRaffles = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public loadFutureRaffleData = () => {
    this.isLoading = true;

    futureRaffleData()
      .then((data) => runInAction(() => (this.upcomingRaffles = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public loadPastRaffleData = () => {
    this.isLoading = true;

    pastRaffleData()
      .then((data) => runInAction(() => (this.pastRaffles = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public loadRaffleById = (raffleId: string) => {
    this.isLoadingSpecificRaffle = true;

    raffleByIdData(raffleId)
      .then((data) => runInAction(() => (this.specificRaffle = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoadingSpecificRaffle = false)));
  };

  @action public loadWinnerData = () => {
    this.isLoading = true;

    winnerData()
      .then((data) => runInAction(() => (this.winnerData = data)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  @action public betOnRaffle = (
    raffleId: string,
    successAction: () => void,
    errorAction: () => void,
  ) => {
    betOnRaffle(raffleId)
      .then(() => {
        this.loadActiveRaffles();
        successAction();
      })
      .catch((error) => {
        handleRequestError(error, [
          ErrorCode.NotEnoughLoyalityPoints,
          () => {
            showErrorNotification(ErrorCode.NotEnoughLoyalityPoints);
            errorAction();
          },
        ]);
      });
  };
}

const { provider, useStore } = setupMobX<RaffleStore>();
export const useRaffleStore = useStore;
export const RaffleStoreProvider = provider;
