/* eslint-disable react/jsx-key */
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { Banner } from '.';
import { Flex, Space, Text } from '@mantine/core';
import { useWindowSize } from '../../hooks';

export const OpeningHoursBanner = observer(
  ({
    maxWidth = '1100px',
    padding = '0px 20px 10px 20px',
  }: {
    maxWidth?: string;
    padding?: string;
  }) => {
    const isSmallScreen = useWindowSize(600);
    const bannerEndDate = DateTime.fromObject({
      year: 2025,
      month: 1,
      day: 4,
    });

    const showBanner = bannerEndDate >= DateTime.now().startOf('day');
    if (!showBanner) return;

    const title = `FESTTAGE: UNSERE ÖFFNUNGSZEITEN`;

    return (
      <Banner padding={padding} maxWidth={maxWidth} title={title}>
        <Text>Wir haben in dieser Zeit für dich auch montags geöffnet. </Text>
        <Text>SWISS CANNABIS CENTER ZÜRICH, SCHLIEREN, WINTERTHUR </Text>
        <Space h="20px" />
        <Flex gap={isSmallScreen ? 5 : 40} wrap="wrap">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, auto)',
              gap: '5px 20px',
            }}
          >
            {[
              { day: 'Mo', date: '23.12.2024', h: '13.00-19.00' },
              { day: 'Di', date: '24.12.2024', h: '10.00-16.00' },
              { day: 'Mi', date: '25.12.2024', h: 'geschlossen', c: 'red' },
              { day: 'Do', date: '26.12.2024', h: 'geschlossen', c: 'red' },
              { day: 'Fr', date: '27.12.2024', h: '10.00-19.00' },
              { day: 'Sa', date: '28.12.2024', h: '10.00-18.00' },
            ].map(({ day, date, h, c }) => (
              <>
                <Text fw="500">{day}</Text>
                <Text>{date}</Text>
                <Text c={c}>{h}</Text>
              </>
            ))}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, auto)',
              gap: '5px 20px',
            }}
          >
            {[
              { day: 'Mo', date: '30.12.2024', h: '13.00-19.00' },
              { day: 'Di', date: '31.12.2024', h: '10.00-14.00' },
              { day: 'Mi', date: '01.01.2025', h: 'geschlossen', c: 'red' },
              { day: 'Do', date: '02.01.2025', h: 'geschlossen', c: 'red' },
              { day: 'Fr', date: '03.01.2025', h: '10.00-19.00' },
              { day: 'Sa', date: '04.01.2025', h: '10.00-18.00' },
            ].map(({ day, date, h, c }) => (
              <>
                <Text fw="500">{day}</Text>
                <Text>{date}</Text>
                <Text c={c}>{h}</Text>
              </>
            ))}
          </div>
        </Flex>
      </Banner>
    );
  },
);
