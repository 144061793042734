import { ErrorCode } from '@web/common';
import { RequestErrorType } from '../../request';

type NotificationCodeToCustomMessageReturnType = {
  title: null | string;
  message: null | string;
};
export const notificationCodeToCustomMessage = (
  errorCode: ErrorCode | RequestErrorType | undefined,
): NotificationCodeToCustomMessageReturnType => {
  if (!errorCode) {
    return { title: null, message: null };
  }
  switch (errorCode) {
    case ErrorCode.NoMoreUnusedParticipantIdsAvailable:
      return {
        title: 'Teilnehmer kann aktuell nicht angenommen werden',
        message:
          'Es sind aktuell keine freien Teilnehmer-IDs verfügbar. Bitte kontaktiere den technischen Support damit neue IDs generiert werden können.',
      };
    case ErrorCode.NoOnboardingAppointmentFound:
      return {
        title: 'Unzureichende Datenlage',
        message:
          'Der Bewerber hat noch keinen Onboarding-Termin gebucht, und kann damit noch nicht bearbeitet werden.',
      };
    case RequestErrorType.ParseIssue:
      return {
        title:
          'Es ist ein Fehler während der Verarbeitung der Daten aufgetreten.',
        message:
          'Sollte der Fehler weiterhin bestehen, melden Sie sich bitte per Anfrage beim technischen Support',
      };
    case ErrorCode.EmailVerificationError:
      return {
        title: 'Es gab einen Fehler beim Versenden der E-Mail',
        message:
          'Sollte der Fehler weiterhin bestehen, melden Sie sich bitte per Anfrage beim technischen Support',
      };
    case ErrorCode.CannotCheckItem:
    case ErrorCode.CannotSaveQuestionnaireResult:
      return {
        title: 'Es gab einen Fehler beim Speichern der Daten',
        message:
          'Sollte der Fehler weiterhin bestehen, melden Sie sich bitte per Anfrage beim technischen Support',
      };
    case ErrorCode.CannavigiaRequestError:
    case ErrorCode.CannavigiaAPIError:
      return {
        title: 'Es gab einen Fehler bei der Kommunikation mit Cannavigia.',
        message:
          'Bitte versuchen Sie es später erneut. Sollte der Fehler weiterhin bestehen, melden Sie sich bitte per Anfrage beim technischen Support.',
      };
    case ErrorCode.NotEnoughLoyalityPoints:
      return {
        title: 'Nicht genügend Punkte',
        message:
          'Sie haben nicht genügend Punkte um an der Verlosung teilzunehmen.',
      };
    default:
      return { title: null, message: null };
  }
};
