import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import { showErrorNotification } from '../component/notification';
import {
  AvailableMission,
  getRank,
  getRankPointLimit,
  TierList,
} from '@web/common';
import { getFunds } from '../request/authenticated-requests/contest';

export class ContestStore {
  @observable public contests: AvailableMission[] = [];
  @observable public funds: number = 0;
  @observable public isLoadingFunds: boolean = false;
  @observable public currentRank: keyof typeof TierList | undefined = undefined;
  @observable public rankPointLimit: number | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  @action public loadFunds = () => {
    this.isLoadingFunds = true;
    getFunds()
      .then((res) =>
        runInAction(() => {
          this.funds = res.funds;
          const currentRank = getRank(res.funds);
          this.currentRank = currentRank;
          this.rankPointLimit = getRankPointLimit(currentRank);
        }),
      )
      .catch(() => showErrorNotification())
      .finally(() => (this.isLoadingFunds = false));
  };
}

const { provider, useStore } = setupMobX<ContestStore>();
export const useContestStore = useStore;
export const ContestStoreProvider = provider;
