import { Accordion, Divider, Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const RaffleExpander = ({ endDate }: { endDate: string }) => {
  const { t } = useTranslation('raffle');

  const expanderData = t('ExpanderDetails', {
    returnObjects: true,
    endDate: endDate,
  }) as {
    Title: string;
    Content: string[];
  }[];

  return (
    <div>
      <Divider />

      <Accordion defaultValue={expanderData[0].Title}>
        {expanderData.map((item) => (
          <Accordion.Item key={item.Title} value={item.Title}>
            <Accordion.Control py="10px">
              <Text size="21px" lh="24px" fw="600" c="#202020">
                {item.Title}
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Flex gap="10px" direction="column">
                {item.Content.map((content, index) => (
                  <Text key={index} size="16px" lh="24px" c="#202020">
                    {content}
                  </Text>
                ))}
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};
