import { Space, Text } from '@mantine/core';
import { RaffleStatus, RaffleTime } from '@web/common';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const RaffleStatusText = ({
  status,
  time,
  children,
}: {
  status: RaffleStatus;
  time: RaffleTime;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation('raffle');
  const spaceHeight = '20px';

  // we dont show status text for future raffles
  if (time === RaffleTime.Future) return null;

  if (time === RaffleTime.Active && status === RaffleStatus.NotParticipating) {
    return (
      <>
        <Space h={spaceHeight} />
        {children}
      </>
    );
  }

  const getStatusText = (statusKey: string, color: string) => (
    <>
      <Space h={spaceHeight} />
      <Text c={color}>{t(`Status.Other.${statusKey}`)}</Text>
    </>
  );

  switch (status) {
    case RaffleStatus.NotParticipating:
      return getStatusText(RaffleStatus.NotParticipating, '#4A4A48');
    case RaffleStatus.Participating:
      return getStatusText(RaffleStatus.Participating, '#1570CB');
    case RaffleStatus.NotWinner:
      return getStatusText(RaffleStatus.NotWinner, '#4A4A48');
    case RaffleStatus.Winner:
      return getStatusText(RaffleStatus.Winner, '#3D7F53');
    case RaffleStatus.RaffleOnGoing:
      return getStatusText(RaffleStatus.RaffleOnGoing, '#202020');
    default:
      return null;
  }
};
