import { observer } from 'mobx-react';
import { useContestStore } from '../../../../../../store';
import { Flex, Skeleton, Text } from '@mantine/core';
import { ObtainablePoints } from '../../../../../../component/ObtainablePoints';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../route/Routes';

export const RafflePointsInfo = observer(
  ({ betPoints }: { betPoints?: number }) => {
    const contestStore = useContestStore();
    const navigate = useNavigate();
    const { funds, isLoadingFunds } = contestStore;
    const { t } = useTranslation('homescreen');

    useEffect(() => {
      contestStore.loadFunds();
    }, [contestStore]);

    return (
      <div
        onClick={() => navigate(Routes.Points)}
        style={{ cursor: 'pointer' }}
      >
        <Skeleton visible={isLoadingFunds}>
          <Flex gap="10px">
            <ObtainablePoints
              points={funds}
              direction="row-reverse"
              showPlus={false}
            />
            <Text>
              {betPoints ? 'Punkte gesetzts TODO' : t('AvailablePoints')}
            </Text>
          </Flex>
        </Skeleton>
      </div>
    );
  },
);
