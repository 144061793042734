import { Language } from '@prisma/client';
import {
  CONTENTFUL_ENGLISH_KEY,
  CONTENTFUL_SWISS_GERMAN_KEY,
  RaffleItemResDto,
  RaffleTime,
} from '@web/common';
import { DateTime } from 'luxon';

export function getRaffleTime(startDate: DateTime, endDate: DateTime) {
  if (startDate > DateTime.now()) {
    return RaffleTime.Future;
  }
  if (startDate < DateTime.now() && endDate > DateTime.now()) {
    return RaffleTime.Active;
  }
  return RaffleTime.Past;
}

export function getRaffleData(data: RaffleItemResDto, language: string) {
  const translationKey =
    language === Language.de
      ? CONTENTFUL_SWISS_GERMAN_KEY
      : CONTENTFUL_ENGLISH_KEY;

  const startDate = data.fields.startDate[CONTENTFUL_ENGLISH_KEY];
  const endDate = data.fields.endDate[CONTENTFUL_ENGLISH_KEY];

  const title = data.fields?.title[translationKey];
  const imageUrl =
    data.fields?.prizeImage[CONTENTFUL_ENGLISH_KEY].fields.file[
      CONTENTFUL_ENGLISH_KEY
    ].url; // img only has en
  const betPoints = (data?.fields?.betPoints as number) || undefined;

  return {
    startDate,
    endDate,
    title,
    imageUrl,
    betPoints,
    getRaffleTime,
    status: data.status,
    raffleId: data.sys.id,
  };
}
