import { Flex, Text } from '@mantine/core';
import { StudyDashboardDetailPage } from '../../../../../component/page';
import { useTranslation } from 'react-i18next';
import { LoyalityPointInfo } from '../../home-screen/components';
import { useWindowSize } from '../../../../../hooks';
import { RaffleStore, RaffleStoreProvider } from '../RaffleStore';
import { observer } from 'mobx-react';
import { ActiveRaffles, FutureRaffles, PastRaffles } from '../components';

const RafflesOverviewContent = observer(() => {
  const { t } = useTranslation('raffle');
  const isSmallScreen = useWindowSize(600);

  return (
    <StudyDashboardDetailPage breadcrumbText={`/ ${t('BreadcrumbText')} `}>
      <Flex direction="column" gap="0.5em" style={{ width: '100%' }}>
        <Flex
          gap={20}
          justify={isSmallScreen ? 'flex-start' : 'space-between'}
          direction={isSmallScreen ? 'column' : 'row'}
        >
          <Text fw="600" size="28px" lh="38px">
            {t('Verlosungen')}
          </Text>
          <LoyalityPointInfo />
        </Flex>

        <ActiveRaffles />
        <FutureRaffles />
        <PastRaffles />
      </Flex>
    </StudyDashboardDetailPage>
  );
});

export const RafflesOverviewScreen = () => {
  return (
    <RaffleStoreProvider store={new RaffleStore()}>
      <RafflesOverviewContent />
    </RaffleStoreProvider>
  );
};
