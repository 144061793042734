import { RaffleStoreProvider, RaffleStore } from '../RaffleStore';

const ActiveRafflesContent = () => {
  return <div>ActiveRafflesScreen</div>;
};

export const ActiveRafflesScreen = () => {
  return (
    <RaffleStoreProvider store={new RaffleStore()}>
      <ActiveRafflesContent />
    </RaffleStoreProvider>
  );
};
