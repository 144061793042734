import { observer } from 'mobx-react';
import { Button, Flex, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Path } from '@remix-run/router/history';

export const InteractiveText = observer(
  ({
    text,
    navigateTo,
  }: {
    text: string;
    navigateTo: string | Partial<Path>;
  }) => {
    const navigate = useNavigate();

    return (
      <Flex align="center" justify={'space-around'}>
        <Button variant="transparent" onClick={() => navigate(navigateTo)}>
          <Text size="16px" lh="24px" fw={600} c={'#3D7F53'}>
            {text}
          </Text>
        </Button>
      </Flex>
    );
  },
);
