import {
  raffleItemResDto,
  rafflesResDto,
  raffleWinnersResDto,
} from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';
import { z } from 'zod';

export const activeRaffleData = async () => {
  return authenticatedAPI.get(`/api/raffle/active`, rafflesResDto);
};

export const futureRaffleData = async () => {
  return authenticatedAPI.get(`/api/raffle/future`, rafflesResDto);
};

export const pastRaffleData = async () => {
  return authenticatedAPI.get(`/api/raffle/past`, rafflesResDto);
};

export const raffleByIdData = async (raffleId: string) => {
  return authenticatedAPI.get(`/api/raffle/${raffleId}`, raffleItemResDto);
};

export const winnerData = async () => {
  return authenticatedAPI.get(`/api/raffle/winner`, raffleWinnersResDto);
};

export const betOnRaffle = async (raffleId: string) => {
  return authenticatedAPI.post(
    `/api/loyality-points/bet/${raffleId}`,
    undefined,
    z.any(),
  );
};
