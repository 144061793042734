import { Flex, Text, Image, Space } from '@mantine/core';
import { StudyDashboardDetailPage } from '../../../../../component/page';
import {
  RaffleStoreProvider,
  RaffleStore,
  useRaffleStore,
} from '../RaffleStore';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RaffleDateInfo } from '../components/raffle-item/RaffleDateInfo';
import { RaffleStatusText } from '../components/raffle-item/RaffleStatusText';
import { RaffleExpander, RafflePointsInfo } from '../components/raffle-details';
import { getRaffleData, getRaffleTime } from '../services';
import { BetOnRaffleDialog } from '../components/raffle-dialog';

// https://quickbird.atlassian.net/browse/SCC-639 show winner details
const RaffleDetailsContent = observer(() => {
  const { t } = useTranslation('raffle');
  const { i18n } = useTranslation();
  const { raffleId } = useParams();
  const store = useRaffleStore();
  const isLoading = store.isLoadingSpecificRaffle;

  useEffect(() => {
    if (raffleId) store.loadRaffleById(raffleId);
  }, [raffleId, store]);

  if (isLoading || !store.specificRaffle) return null;

  const {
    startDate,
    endDate,
    title,
    imageUrl,
    betPoints,
    raffleId: id,
  } = getRaffleData(store.specificRaffle, i18n.language);
  const raffleTime = getRaffleTime(startDate, endDate);

  // TODO: https://quickbird.atlassian.net/browse/SCC-643 fix breadcrumbs
  return (
    <StudyDashboardDetailPage breadcrumbText={`/ ${t('BreadcrumbText')}  `}>
      <Flex direction="column" gap="0.5em" style={{ width: '100%' }}>
        <Flex gap={10} direction="column" justify="center">
          <Flex mah="160px" justify="center">
            <Image
              fit="contain"
              src={imageUrl}
              my="0px"
              radius={5}
              mah="160px"
            />
          </Flex>
          <Space h="10px" />
          <Text fw="600" size="28px" lh="38px">
            {title}
          </Text>
          <RaffleDateInfo
            time={raffleTime}
            startDate={startDate}
            endDate={endDate}
          />
          <RafflePointsInfo betPoints={betPoints} />
          <RaffleStatusText
            status={store.specificRaffle?.status}
            time={raffleTime}
          >
            <BetOnRaffleDialog raffleId={id} />
          </RaffleStatusText>

          <RaffleExpander endDate={endDate.toFormat('dd.MM.yyyy')} />
        </Flex>
      </Flex>
    </StudyDashboardDetailPage>
  );
});

export const RaffleDetailsScreen = () => {
  return (
    <RaffleStoreProvider store={new RaffleStore()}>
      <RaffleDetailsContent />
    </RaffleStoreProvider>
  );
};
