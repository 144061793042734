import { Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showSuccessNotification } from '../../../../../../component/notification';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../../../../../component/userStatus/dialogs';
import { RaffleStatus } from '@web/common';
import { useRaffleStore } from '../../RaffleStore';
import { useContestStore } from '../../../../../../store';

export const BetOnRaffleDialog = observer(
  ({ raffleId }: { raffleId: string }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const contestStore = useContestStore();
    const store = useRaffleStore();
    const { t } = useTranslation('raffle');

    function onSuccess() {
      close();
      contestStore.loadFunds();
      showSuccessNotification(
        t('BetDialog.SubmitSuccessMsg'),
        t('BetDialog.SubmitSuccessTitle'),
      );
    }

    function onError() {
      close();
    }

    return (
      <div>
        <Button w="100%" onClick={open}>
          {t(`Status.Active.${RaffleStatus.NotParticipating}`)}
        </Button>

        <BaseModal
          opened={opened}
          onClose={close}
          withCloseButton
          title="Punkte setzen, um an der Verlosung teilzunehmen?"
          description="Wenn du an dieser Verlosung teilnimmst, werden alle deine Punkte eingesetzt & du startest wieder mit 0 Punkten. Du kannst dir die Punkte auch sparen & an der nächsten Verlosung einsetzen. "
          size="500px"
          children={
            <Flex direction="column" gap="10px">
              <Button
                onClick={() => store.betOnRaffle(raffleId, onSuccess, onError)}
                w="100%"
                key="bet"
              >
                {t('BetDialog.Bet')}
              </Button>
              <Button variant="outline" onClick={close} w="100%" key="cancel">
                {t('BetDialog.Cancel')}
              </Button>
            </Flex>
          }
        />
      </div>
    );
  },
);
