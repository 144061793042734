import { Routes } from '../../../route/Routes';
import { Container, Flex, Center } from '@mantine/core';
import classes from './LandingPage.module.css';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useKeycloakStore } from '../../../store';

import {
  HowItWorks,
  FAQShort,
  AboutUs,
  Contact,
  Intro,
  Cooperations,
  Testimonials,
} from './sections';
import {
  CohortEndingBanner,
  Loading,
  OpeningHoursBanner,
} from '../../../component';
import { LandingPageHeader } from '../navigation';
import { JoinAndProfit } from './sections/JoinAndProfit';
import { useWindowSize } from '../../../hooks';

export const LandingPage = observer(() => {
  const kcStore = useKeycloakStore();
  const isSmallScreen = useWindowSize(600);

  if (!kcStore.initialized && !kcStore.initializingInProgress) {
    kcStore.initKeycloak();
    return <Loading />;
  }

  if (kcStore.authenticated) {
    return <Navigate to={Routes.Study} />;
  }

  return (
    <Container
      fluid
      className={classes.container}
      p={0}
      style={{ overflow: 'clip' }}
    >
      <LandingPageHeader />
      <Center>
        <Flex className={classes.sectionWrapper}>
          {!isSmallScreen && (
            <CohortEndingBanner padding="20px 20px 0px 20px" />
          )}
          <OpeningHoursBanner />
          <Intro id="intro" />
          {isSmallScreen && <CohortEndingBanner padding="20px" />}
          <JoinAndProfit id="joinandprofit" />
          <Cooperations />
          <HowItWorks id="howitworks" />
          <FAQShort id="faq" />
          <Testimonials />
          <AboutUs id="aboutus" />
          <Contact id="contact" />
        </Flex>
      </Center>
    </Container>
  );
});
