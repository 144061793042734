import { Flex, Image, Text } from '@mantine/core';
import { Card } from '../../../../../../component';
import { RaffleItemResDto, RaffleTime } from '@web/common';
import { useWindowSize } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { RaffleStatusText } from './RaffleStatusText';
import { RaffleDateInfo } from './RaffleDateInfo';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../route/Routes';
import { getRaffleData } from '../../services';
import { BetOnRaffleDialog } from '../raffle-dialog';

export const RaffleItem = ({
  data,
  time,
}: {
  data: RaffleItemResDto;
  time: RaffleTime;
}) => {
  const { i18n } = useTranslation();
  const { startDate, endDate, title, imageUrl, status, raffleId } =
    getRaffleData(data, i18n.language);
  const isSmallScreen = useWindowSize(600);
  const navigate = useNavigate();

  return (
    <Card
      flexProps={{ p: isSmallScreen ? '18px' : '24px' }}
      onClick={() => navigate(`${Routes.Study}/${Routes.Raffles}/${raffleId}`)}
    >
      <Flex gap="20px" align="stretch">
        <Flex justify="center" align="center" w={isSmallScreen ? 60 : 80}>
          <Image fit="contain" src={imageUrl} my="0px" radius={5} mah="80px" />
        </Flex>

        <Flex direction="column" justify="space-between">
          <Text fw={600} size="16px" lh="24px">
            {title}
          </Text>

          <RaffleDateInfo time={time} startDate={startDate} endDate={endDate} />
        </Flex>
      </Flex>

      <RaffleStatusText status={status} time={time}>
        <BetOnRaffleDialog raffleId={data.sys.id} />
      </RaffleStatusText>
    </Card>
  );
};
