export enum ErrorCode {
  UnknownError = 'E0000',

  // logic errors
  NoMoreUnusedParticipantIdsAvailable = 'E1001',
  InvalidStateChange = 'E1002',
  UserAlreadyInCohort = 'E1003',
  NoOnboardingAppointmentFound = 'E1004',
  UserDeactivated = 'E1005',
  InvalidToken = 'E1006',
  BaselineSurveyLinksAmountWrong = 'E1010',
  UserStatusChangeNotAllowed = 'E1011',
  NotEnoughLoyalityPoints = 'E1012',
  UnknownAnonymousQuestionnaireEvaluationRecordType = 'E1014',
  AnonymizeUserFailed = 'E1015',
  NotPartOfACohortYet = 'E1016',
  NoBAGSurveyFound = 'E1017',
  NoSmsContentFound = 'E1018',
  NotAllowedForThisParticipant = 'E1019',
  NoMatchingDispensaryFound = 'E1020',
  AclError = 'E1021',
  CannotGetMissionPoints = 'E1022',
  NotAllowedForThisMissionType = 'E1023',
  UserEmailNotFound = 'E1024',
  InvalidPrice = 'E1025',
  NoDispensaries = 'E1026',
  DispensaryMatchError = 'E1027',
  OrderNotFound = 'E1028',
  OnboardingReminderReasonCount = 'E1029',
  AlreadyRegisteredEmailOnce = 'E1030',
  AlreadyRegisteredPhoneNumberOnce = 'E1031',
  UserRegisterError = 'E1032',
  PodcastAlreadyMarkedAsFinished = 'E1033',
  PodcastNotYetUnlocked = 'E1034',
  PodcastStatReadingError = 'E1035',
  PodcastHeaderRangeInvalid = 'E1036',
  UnknownHorizon = 'E1037',
  WrongReasonType = 'E1038',

  // raffle errors
  NotEligibleForRaffle = 'R0001',
  AlreadyParticipatedInRaffle = 'R0002',
  CannotBetOnRaffle = 'R0003',

  // validation errors
  SchemaValidationError = 'E0001',
  MissingXCalSignatureHeader = 'E0004',
  InvalidWebhookHash = 'E0005',
  InvalidWebhookContent = 'E0006',
  InvalidParticipantIdSurveyData = 'E0007',
  InvalidBagIdSurveyData = 'E0008',
  IssueHandlingBookingCreatedWebhook = 'E0009',

  // prisma errors
  RecordNotFound = 'P1000',
  ForeignKeyConstraintFailed = 'P1001',
  UniqueConstraintFailed = 'P1002',
  PrismaPostgreSQLErrorConnection = 'P1003',
  GenericPrismaError = 'P1999',

  // cal.com errors
  GenericCalDotComError = 'C0000',
  ParseCalDotComError = 'C0001',
  CalDotComRequestError = 'C0002',
  CalDotComCacheNotLoaded = 'C0003',

  // token errors
  MissingAuthorizationHeader = 'A2000',
  FaultyAuthorizationHeader = 'A2001',

  // Qualtrix errors
  QualtrixLinkRequestError = 'Q0000',

  // OneSignal errors
  OneSignalNotificationError = 'O0001',

  // Cannavigia errors
  CannavigiaRequestError = 'CA0000',
  CannavigiaMissingCookie = 'CA0001',
  CannavigiaInvalidCookie = 'CA0002',
  CannavigiaMetaDataTypeError = 'CA0003',
  CannavigiaOTPError = 'CA0004',
  CannavigiaAPIError = 'CA0005',
  CannavigiaMissingRefreshToken = 'CA0006',
  CannavigiaMissingAccessToken = 'CA0007',
  CannavigiaHashAndUserAlreadyExists = 'CA0008',
  CannavigiaDispensaryParsingError = 'CA0009',
  CannavigiaMissingCookiesError = 'CA0010',
  CannavigiaMissingTokenError = 'CA0011',
  CannavigiaMissingCSRFCookies = 'CA0012',
  CannavigiaFailed2FARequest = 'CA0013',
  CannavigiaMissingOTPSecret = 'CA0014',

  // keycloak errors
  KeycloakConnection = 'K0000',
  KeycloakUserAlreadyExists = 'K0001',
  KeycloakUserDoesntExist = 'K0002',
  KeycloakResponseParseError = 'K0003',
  KeycloakFailedToRetrieveUsersByRole = 'K0004',
  KeycloakClientRetrieval = 'K0005',
  KeycloakFailedToRetrieveGroup = 'K0006',
  KeycloakFailedToSetPasswordForUser = 'K0007',
  KeycloakRoleRetrieval = 'K0008',
  KeycloakFailedToUpdateUserMail = 'K0009',

  // shopify errors
  ShopifyRequestError = 'S0000',
  ShopifyParsingError = 'S0001',
  ShopifyOrderCancel = 'S0002',
  FulfillmentOrderNotFound = 'S0003',
  ShopifyMarkAsPickedUp = 'S0004',

  // Twilio errors
  TwilioSignatureInvalid = 'T0001',
  TwilioMissingSignature = 'T0002',

  // Sendgrid errors
  SendGridError = 'SG0000',
  SendGridValidation = 'SG0001',

  // contentful Errors
  ContentfulRequestError = 'CF0000',
  ContentfulParseError = 'CF0001',
  ContentfulCacheError = 'CF0002',

  // frontend errors
  GenericError = 'F0000',
  EmailVerificationError = 'F0001',
  CannotSaveQuestionnaireResult = 'F0003',
  CannotCheckItem = 'F0004',
  CannotLoadChecklist = 'F0005',
  CannotLoadDashboard = 'F0006',

  QuestionnaireParsingError = 'F0002',
}
