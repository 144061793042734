import { Flex, Space, Text } from '@mantine/core';
import { StudyDashboardDetailPage } from '../../../../../component/page';
import { FutureRaffles } from '../components';
import { RaffleStoreProvider, RaffleStore } from '../RaffleStore';
import { useWindowSize } from '../../../../../../src/hooks';
import { useTranslation } from 'react-i18next';

const FutureRafflesContent = () => {
  const { t } = useTranslation('raffle');
  const isSmallScreen = useWindowSize(600);

  return (
    <StudyDashboardDetailPage
      breadcrumbText={`/ ${t('BreadcrumbText')} / ${t('UpcomingRaffles')} `}
    >
      <Flex direction="column" gap="0.5em" style={{ width: '100%' }}>
        <Flex
          gap={20}
          justify={isSmallScreen ? 'flex-start' : 'space-between'}
          direction={isSmallScreen ? 'column' : 'row'}
        >
          <Text fw="600" size="28px" lh="38px">
            {t('UpcomingRaffles')}
          </Text>
        </Flex>
        <Space h="10px" />
        <FutureRaffles hideHeader />
      </Flex>
    </StudyDashboardDetailPage>
  );
};

export const FutureRafflesScreen = () => {
  return (
    <RaffleStoreProvider store={new RaffleStore()}>
      <FutureRafflesContent />
    </RaffleStoreProvider>
  );
};
