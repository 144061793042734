import { Flex, Skeleton, Text } from '@mantine/core';
import { Card } from '../../../../../../component';
import { observer } from 'mobx-react';
import { useContestStore } from '../../../../../../store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../route/Routes';
import { ObtainablePoints } from '../../../../../../component/ObtainablePoints';
import { useTranslation } from 'react-i18next';

export const LoyalityPointInfo = observer(() => {
  const contestStore = useContestStore();
  const navigate = useNavigate();
  const { funds, currentRank, rankPointLimit } = contestStore;
  const { t } = useTranslation('homescreen');

  useEffect(() => {
    contestStore.loadFunds();
  }, [contestStore]);

  return (
    <div onClick={() => navigate(Routes.Points)} style={{ cursor: 'pointer' }}>
      <Card size="small">
        <Skeleton visible={!currentRank}>
          <Flex gap="10px">
            <ObtainablePoints
              points={rankPointLimit ? funds + '/' + rankPointLimit : funds}
              direction="row-reverse"
              showPlus={false}
            />

            <Text>{t('TierList.' + currentRank)}</Text>
          </Flex>
        </Skeleton>
      </Card>
    </div>
  );
});
